import { ApolloProvider } from '@apollo/client';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import { AppProps } from 'next/app';
import apolloClient from '@config/apollo-client';
import { DevTools } from 'jotai-devtools';
import './styles.scss';
import '@bice_vida/ds-theme/onboarding';
import '@BICE_Vida/components/dist/main.css';
import '@BICE_Vida/tsp-components/dist/index.css';

function AsesorDigitalApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Asesor Digital BiceVida</title>
      </Head>
      <main className="app">
        {process.env.STAGE === 'local' ? <DevTools /> : undefined}
        <ApolloProvider client={apolloClient}>
          <Component {...pageProps} />
        </ApolloProvider>
      </main>
    </>
  );
}

export default appWithTranslation(AsesorDigitalApp);
