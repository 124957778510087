import { ApolloClient, InMemoryCache } from '@apollo/client';

const apolloClient = new ApolloClient({
  uri: process.env.FEDERATION_GATEWAY || '',
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default apolloClient;
